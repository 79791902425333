<template>
  <div>
    <div
      class="map"
      :class="{ mapRight: mapSide === 'right' }"
      @click="cancleProfitBg"
    >
      <button class="mountStatus" @click="gotoCheckStatus(1)">
        <span>矿山状态</span>
      </button>
      <button class="unionStatus" @click="gotoCheckStatus(2)">
        <span>公会状态</span>
      </button>

      <div v-if="mapSide === 'left'">
        <div
          :class="[item.symbol]"
          v-for="(item, index) in minesList"
          :key="index"
          v-show="item.country == 1"
          @click.stop="handleMine(index, item)"
        >
          <div
            class="imgBox"
            :class="chosedMineIndex == index ? 'active' : ''"
          ></div>
          <p>{{ item.name }}({{ getBonusPosi(item.bonus_position) }})</p>
          <!-- 矿山星级 -->
          <div class="starts">
            <span v-for="(starts, index) in item.level" :key="index"></span>
          </div>
          <!-- 矿山状态  可争夺,可报名,可开采 -->
          <div
            class="statusBox"
            :class="getMineStateClass(item.state, item.current_campaign)"
          >
            <p>{{ getMineStateTxt(item.state, item.current_campaign) }}</p>
          </div>
          <div class="profitBox" v-show="chosedPfofitIndex == index">
            <div>
              <p>最高存储量(圣物)</p>
              <p>{{ item.max_store }}</p>
            </div>
            <div>
              <p>每天收益(灵气)</p>
              <p>{{ item.yield }}</p>
            </div>
            <div>
              <p>APY</p>
              <p>{{ getRate(item.level) }}%</p>
            </div>
            <div>
              <p>最低要求活跃度</p>
              <p>{{ getRateByYear(item.level) }}</p>
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        <div
          :class="[item.symbol]"
          v-for="(item, index) in minesList"
          :key="index"
          v-show="item.country == 2"
          @click.stop="handleMine(index, item)"
        >
          <div
            class="imgBox"
            :class="chosedMineIndex == index ? 'active' : ''"
          ></div>
          <p>{{ item.name }}({{ getBonusPosi(item.bonus_position) }})</p>
          <div class="starts">
            <span v-for="(starts, index) in item.level" :key="index"></span>
          </div>
          <div
            class="statusBox"
            :class="getMineStateClass(item.state, item.current_campaign)"
          >
            <p>{{ getMineStateTxt(item.state, item.current_campaign) }}</p>
          </div>
          <div class="profitBox" v-show="chosedPfofitIndex == index">
            <div>
              <p>最高存储量(圣物)</p>
              <p>{{ item.max_store }}</p>
            </div>
            <div>
              <p>每天收益(灵气)</p>
              <p>{{ item.yield }}</p>
            </div>
            <div>
              <p>APY</p>
              <p>{{ getRate(item.level) }}%</p>
            </div>
            <div>
              <p>最低要求活跃度</p>
              <p>{{ getRateByYear(item.level) }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="switch-map">
        <div class="btns">
          <button
            :class="{ active: mapSide === 'left' }"
            @click="handleSwitchMap('left')"
          >
            西部大陆
          </button>
          <button
            :class="{ active: mapSide === 'right' }"
            @click="handleSwitchMap('right')"
          >
            东部王国
          </button>
        </div>
      </div>
      <div class="strategy-btn" @click="goRule"></div>
      <div class="start-btn" @click="startSnatch">开启争夺</div>
      <a href="https://v1.fivexw.com/#/profile"
        ><div class="profile-btn"></div
      ></a>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import * as utils from "../utils";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import { getMines } from "../apis/httpReq";
import { watch } from "@vue/runtime-core";
import { useStore } from "vuex";

//状态暂时无法对应--需要调整修改
const mineStateTxt = (status) => {
  const mineStatus = {
    0: "",
    1: "可争夺",
    2: "可争夺",
    3: "争",
    4: "开采中",
    5: "可报名",
    6: "争",
    7: "可争夺",
    8: "争",
  };
  return mineStatus[status];
};

//矿山不同状态对应的class--需要调整修改
const mineStateClass = (status) => {
  const mineStatus = {
    0: "", //开采中
    1: "canFight", //可争夺
    2: "canFight", //守
    3: "fight", //攻
    4: "mining", //争
    5: "canFight",
    6: "fight",
    7: "canFight",
    8: "fight",
  };
  return mineStatus[status];
};
//APY
const getRate = (l) => {
  switch (l) {
    case 1:
      return 182.5;
    case 2:
      return 146;
    case 3:
      return 182.5;
    case 4:
      return 456.25;
    case 5:
      return 547.5;
    default:
      break;
  }
  return;
};
//活跃度
const getRateByYear = (l) => {
  switch (l) {
    case 1:
      return 70;
    case 2:
      return 200;
    case 3:
      return 800;
    case 4:
      return 3000;
    case 5:
      return 5000;
    default:
      break;
  }
  return;
};

export default {
  name: "Maps",
  data() {
    return {
      msg: "test",
    };
  },
  setup: async () => {
    const store = useStore();
    const router = useRouter();
    let minesList = ref([]);
    let addr = ref("");
    minesList.value = await getMines(addr.value);
    let mapSide = ref("left");
    let chosedMineIndex = ref("-1"); //当前选中的矿山的index--显示矿山选中
    let chosedPfofitIndex = ref("-1"); //当前选中的矿山的index--显示收益弹框
    let currentChosedMine = ref(null); //当前选中的矿山数据

    watch(
      () => store.state.websockData,
      (newValue) => {
        const data = JSON.parse(JSON.stringify(newValue));
        if (data?.code == "fv228" && data?.data) {
          for (const key in minesList.value) {
            if (minesList.value[key].ID === data.data.ID) {
              minesList.value[key] = data.data;
            }
          }
        }
      },
      { deep: true }
    );

    //地图切换
    const handleSwitchMap = (side) => {
      mapSide.value = side;
      chosedMineIndex.value = -1;
      chosedPfofitIndex.value = -1;
    };

    //选择矿山
    const handleMine = (index, item) => {
      chosedMineIndex.value = index;
      currentChosedMine.value = item;
      chosedPfofitIndex.value = index;
      console.log(index, "当前索引");
      console.log(currentChosedMine.value, "当前选中的矿山数据");
    };

    //查看矿山/工会状态
    const gotoCheckStatus = (status) => {
      if (status == 2) {
        router.push({ path: "unionlist" });
      } else {
        router.push({ path: "mountstatus" });
      }
    };
    //规则
    const goRule = (status) => {
      router.push({ path: "rule" });
    };
    const goMyUnion = (status) => {
      router.push({ path: "./unionstatus" });
    };

    // 操作按钮处理--开启争夺(展示不用)
    const startSnatch = () => {
      console.log(currentChosedMine.value, 888888);
      if (currentChosedMine.value?.ID == null) {
        ElMessage.warning({
          message: "请先选择一个矿山！",
          type: "warning",
        });
        return;
      }
      if (currentChosedMine.value?.state == 0) {
        ElMessage.warning({
          message: "矿山暂未开放！",
          type: "warning",
        });
        return;
      }
      router.push({
        path: "pk",
        query: { mineId: currentChosedMine.value?.ID },
      });
    };
    //矿山状态-开采中/攻/守
    const getMineStateTxt = (status, campaign) => {
      if (!campaign && status == 6) {
        return "开采中";
      } else {
        return mineStateTxt(status);
      }
    };

    //矿山状态(标签)ClassName渲染: 攻/守/可开采/可开采
    const getMineStateClass = (state, campaign) => {
      if (!campaign && state == 6) {
        return "mining";
      } else {
        return mineStateClass(state);
      }
    };

    utils.getAccount().then((a) => console.log(a));

    //矿山属性处理--金木水火土
    const getBonusPosi = (bonus) => {
      return utils.getMinesBonus(bonus);
    };

    //取消矿山收益弹框
    const cancleProfitBg = () => {
      chosedPfofitIndex.value = -1;
    };

    return {
      mapSide,
      handleSwitchMap,
      chosedMineIndex,
      getRateByYear,
      handleMine,
      goRule,
      goMyUnion,
      getRate,
      gotoCheckStatus,
      startSnatch,
      currentChosedMine,
      minesList,
      getBonusPosi,
      getMineStateTxt,
      getMineStateClass,
      chosedPfofitIndex,
      cancleProfitBg,
    };
  },
};
</script>
